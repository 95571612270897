<template>
  <div>
    <div>
      <div class="top-line">
        <div style="display: flex; flex-direction: row; align-items: center">
          <div style="margin-right: 20px">反馈类型:</div>
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全部</el-checkbox
          >
          <el-checkbox-group
            style="margin-left: 30px"
            v-model="checkList"
            @change="selectType"
          >
            <el-checkbox label="1">功能异常</el-checkbox>
            <el-checkbox label="2">产品建议</el-checkbox>
            <el-checkbox label="3">其他</el-checkbox>
          </el-checkbox-group>
        </div>
        <div @click="$router.push('/feedback')" class="feedback">我要反馈</div>
      </div>
    </div>
    <Table
      v-if="maxHeight"
      :max-height="maxHeight"
      :isShowOverflow="false"
      :data1="tableData"
      :columns1="columns"
      :total="total"
      @getAjax="getTableData"
      :loading="loading"
      :page="searchData.page"
      :size="searchData.size"
    />
    <el-drawer title="查看反馈详情" :visible.sync="detail">
      <div style="display: flex; flex-direction: column">
        <div style="margin-bottom: 20px">
          补充说明：{{ detailInfo.feedbackTypeName }}
        </div>
        <div style="margin-bottom: 20px">反馈人：{{ detailInfo.user }}</div>
        <div style="margin-bottom: 20px">
          反馈时间：{{ detailInfo.feedbackTime }}
        </div>
        <div style="margin-bottom: 20px">
          反馈内容：{{ detailInfo.feedbackContent }}
        </div>
        <div style="display: flex; flex-direction: row; flex-wrap: wrap">
          <div style="width: 80px">反馈图片：</div>
          <div>
            <el-image
              style="width: 260px; height: 180px"
              v-for="(item, index) in detailInfo.feedbackImg"
              :key="index"
              :src="item"
              fit="contain"
            ></el-image>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-dialog title="删除" :visible.sync="delFeedback" width="30%">
      <span>确认删除这条反馈意见？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delFeedback = false">取 消</el-button>
        <el-button type="primary" @click="deletedFeedback()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from '../../components/Table.vue'
import { _GetFeedback, _DeletedFeedback } from '@/api/userFeedback'

export default {
  components: {
    Table
  },
  name: 'myFeedback',
  data() {
    return {
      checkAll: false,
      isIndeterminate: false,
      total: 0,
      loading: false,
      delFeedback: false,
      checkList: [],
      imageList: [],
      detail: false,
      rowData: {},
      maxHeight: 0,
      detailInfo: {
        feedbackTypeName: '',
        user: '',
        feedbackTime: '',
        feedbackContent: '',
        feedbackImg: []
      },
      searchData: {
        page: 1,
        size: 10,
        feedbackTypes: [],
        ifSelf: 0
      },
      tableData: [],
      columns: [
        {
          label: '反馈人',
          prop: 'user',
          width: '100px'
        },
        {
          label: '反馈类型',
          prop: 'feedbackTypeName',
          width: '100px'
        },
        {
          label: '反馈内容',
          prop: 'feedbackContent',
          width: '650px'
        },
        {
          label: '缩略配图',
          width: '240px',
          render: (h, params) => {
            let group = []
            let srcList = params.row.imageList.map(ele => {
              return ele.url
            })
            if (params.row.imageList.length > 0) {
              // console.log(params.row.imageList)
              // console.log(params.row.imageList[0].url,999)
              for (let i = 0; i < params.row.imageList.length; i++) {
                group.push(
                  h('el-image', {
                    style: {
                      width: '70px',
                      height: '40px'
                    },
                    attrs: {
                      src: params.row.imageList[i].url,
                      'preview-src-list': srcList,
                      fit: 'cover'
                    }
                  })
                )
              }
            }
            return h('div', group)
          }
        },
        {
          label: '反馈时间',
          prop: 'feedbackTime',
          width: '240px'
        },
        {
          label: '操作项',
          fixed: 'right',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'operationA',
                  on: {
                    click: () => {
                      this.detail = true
                      this.detailInfo.feedbackTypeName =
                        params.row.feedbackTypeName
                      this.detailInfo.user = params.row.user
                      this.detailInfo.feedbackTime = params.row.feedbackTime
                      this.detailInfo.feedbackContent =
                        params.row.feedbackContent
                      this.detailInfo.feedbackImg = params.row.imageList.map(
                        ele => {
                          return ele.url
                        }
                      )
                    }
                  }
                },
                '查看详情'
              )
            ])
          }
        }
      ]
    }
  },
  methods: {
    async getTableData(pagination) {
      if (pagination) {
        if (pagination.page) {
          this.searchData.page = pagination.page
          this.searchData.size = pagination.limit
        }
      }
      let { data } = await _GetFeedback(this.searchData)
      this.tableData = data ? data.queryUserWebFeedbackResponseList : ''
      this.total = data.total
      this.size = data.pageSize
    },
    handleCheckAllChange(val) {
      this.searchData.page = 1
      this.searchData.size = 10
      this.searchData.feedbackTypes = ''
      this.checkList = val ? ['1', '2', '3'] : []
      this.isIndeterminate = false
      this.getTableData()
    },
    selectType(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === 3
      this.isIndeterminate = checkedCount > 0 && checkedCount < 3
      this.searchData.page = 1
      this.searchData.size = 10
      this.searchData.feedbackTypes = this.checkList.toString()
      this.getTableData()
    },
    async deletedFeedback() {
      let formdata = new FormData()
      formdata.append('id', this.rowData.id)
      let { success, data, message } = await _DeletedFeedback(formdata)
      if (success) {
        this.$message.success('删除成功')
        this.delFeedback = false
        await this.getTableData()
      } else {
        this.$message.error(message)
      }
    }
  },
  mounted() {
    this.getTableData()
    this.maxHeight = document.documentElement.clientHeight - 300
  }
}
</script>

<style scoped>
.top-line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: space-between;
}
.feedback {
  width: 72px;
  height: 36px;
  font-size: 14px;
  background: #1683d9;
  box-shadow: 0px 0px 10px 0px rgba(22, 131, 217, 0.6);
  color: white;
  font-weight: bold;
  text-align: center;
  line-height: 36px;
}
::v-deep .el-drawer__header {
  font-size: 18px;
  margin-bottom: 20px;
  padding: 10px 20px 0;
}
::v-deep .el-drawer__body {
  font-size: 14px;
}
</style>

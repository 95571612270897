import { render, staticRenderFns } from "./allFeedback.vue?vue&type=template&id=46ef52d8&scoped=true&"
import script from "./allFeedback.vue?vue&type=script&lang=js&"
export * from "./allFeedback.vue?vue&type=script&lang=js&"
import style0 from "./allFeedback.vue?vue&type=style&index=0&id=46ef52d8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46ef52d8",
  null
  
)

export default component.exports